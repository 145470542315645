<template>
  <div>
    <BaseModalContent
      name="attempt-modal"
      :dynamic="true"
      @close="$emit('close')">
      <div>
        <h2 class="text-size-h2 text-bold mb-m">
          <span v-if="activity.title !== attempt.title"
            >{{ activity.title }}: {{ attempt.title }}</span
          >
          <span v-else>{{ activity.title }}</span>
        </h2>
        <div class="typography">
          <div v-if="currentStatus === 'past'">
            <p>Задание завершено</p>
          </div>
          <div v-if="attemptScores > 0 || attemptScores === 0">
            <p>Ваш балл: {{ attemptScores }}</p>
          </div>
          <div v-else-if="currentStatus === 'upcoming'">
            <p>
              Задание еще не началось. Возможность решать это задание появится
              через {{ startAfter }}.
            </p>
          </div>
          <div>
            <p>
              Дата начала: {{ formmatedDates.start }}<br />
              Дата окончания: {{ formmatedDates.end }}
            </p>
          </div>
          <div v-if="activity.stepik_course_id">
            <p>
              Это задание размещено на платформе Яндекс.Контест.
              <span v-if="yandexId">
                <br />Ваш Яндекс акаунт
                {{ yandexId }}
              </span>
            </p>
          </div>
          <div v-if="profiles">
            <strong>Профили:</strong>
            <ul>
              <li
                v-for="item in profiles"
                :key="item.id">
                <a
                  href="#"
                  @click.prevent="handleProfileClick(item.id)"
                  >{{ item.title }}</a
                >
              </li>
            </ul>
          </div>
          <div class="mt-l">
            <div
              v-if="requireTeam"
              class="mb-m">
              Для того, чтобы получить доступ к&nbsp;заданию, вам нужно создать
              или вступить в&nbsp;команду по этому профилю.
            </div>

            <div>
              <EnrollButton
                v-if="activity.stepik_course_id"
                :activity="activity"
                :is-enrolled="attemptScores >= 0"
                :disabled="isDisabled"
                >Перейти к заданию</EnrollButton
              >

              <BaseButton
                v-else-if="activity.external_url"
                tag="a"
                :href="activity.external_url"
                target="_blank"
                rel="noopener noreferrer"
                class="activity-card__btn mb-s mt-s"
                :disabled="isDisabled"
                >Перейти к заданию</BaseButton
              >
            </div>

            <div
              v-if="isMentor && !mentorCanEnroll"
              class="text-size-s">
              <div v-if="mentorEnrollmentDate">
                Возможность просмотреть это задание для наставников появится
                после
                <span> {{ mentorEnrollmentDate }}</span
                >.
              </div>
              <div v-else>Пока нет возможности пройти это задание</div>
            </div>
          </div>
        </div>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import {
  DATE_FROMAT_WITH_UTC,
  INDIVIDUAL_STAGE,
  TEAM_STAGE,
  TEAM_STAGE_ENROLL_DAYS,
} from "@/constants";
import EnrollButton from "@/components/EnrollButton";
import { getFirstAttempt } from "@/utils/profile";
export default {
  name: "AttemptModal",
  components: {
    EnrollButton,
  },
  props: {
    attempt: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    allProfiles() {
      return this.$store.state.profile.profiles;
    },
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
      isHighSchool: "participant/isHighSchool",
      yandexId: "user/yandexId",
    }),
    formmatedDates() {
      const { attempt } = this;
      const result = {
        start: "Не указана",
        end: "Не указана",
      };

      if (!attempt) return result;

      result.start = dayjs(attempt.start_at).format(DATE_FROMAT_WITH_UTC);
      result.end = dayjs(attempt.end_at).format(DATE_FROMAT_WITH_UTC);

      return result;
    },
    profiles() {
      const { isMentor, activity, allProfiles } = this;
      if (!activity.profiles) return [];
      return activity.profiles.map((n) => {
        const profile = allProfiles[n];
        if (profile) {
          const link = {
            params: {},
          };
          if (isMentor) {
            link.name = "mentor-profile";
            link.params.id = profile.id;
          } else {
            link.name = "user-profile-stage-1";
            link.params.profile_id = profile.id;
          }
          return {
            title: profile.title,
            id: profile.id,
            link,
          };
        }
        return undefined;
      });
    },
    currentStatus() {
      const { attempt } = this;
      if (!attempt || !attempt.end_at || !attempt.start_at) return "";
      const isPast = dayjs().isAfter(attempt.end_at);
      const isUpcoming = dayjs().isBefore(attempt.start_at);
      if (isPast) {
        return "past";
      }
      if (isUpcoming) {
        return "upcoming";
      }
      if (!isUpcoming && !isPast) return "current";
      return "";
    },
    startAfter() {
      const { attempt } = this;
      if (!attempt || !attempt.start_at) return;
      return dayjs().to(attempt.start_at);
    },
    attemptScores() {
      const scores = this.$store.state.participant.scores?.attempt_scores;
      if (!Array.isArray(scores)) return;
      const current = scores.find((n) => n.attempt_id === +this.attempt.id);
      return current?.score;
    },
    requireTeam() {
      const { activity, isMentor } = this;
      if (isMentor) return false;
      return (
        activity.stage === TEAM_STAGE &&
        !activity.individual_activity &&
        !activity.hasTeam
      );
    },
    isDisabled() {
      const { currentStatus, requireTeam, isMentor, mentorCanEnroll } = this;
      if (isMentor) {
        return currentStatus === "upcoming" || !mentorCanEnroll;
      }
      // нельзя пройтив в активность которая еще не началась,
      // или уже закончилась, или если требуется команда, а команды нет
      const disabled = requireTeam || currentStatus !== "current";
      return disabled;
    },
    // дата, когда наставнику можно пройти задание
    mentorEnrollmentDate() {
      const { firstAttempt, activity, list, isMentor } = this;
      if (!isMentor) return undefined;
      let date;
      // в индивидуальном этапе, наставник может энролиться
      // после первой попытки
      if (activity.stage === INDIVIDUAL_STAGE) {
        if (activity.stepik_course_id && firstAttempt) {
          date = dayjs(firstAttempt.end_at);
        }
      } else if (activity.stage === TEAM_STAGE && activity.end_at) {
        let teamDate;
        // В командном этапе нужно
        // смотреть на дату окончания активности, если это внешняя без попыток
        if (!list?.length) {
          teamDate = activity.end_at;
        } else {
          // В командном этапе нужно
          // если есть попытки то нужно найти последнюю
          const sorted = list
            .filter((n) => n.end_at)
            .sort((a, b) => {
              return (
                new Date(b.end_at).getTime() - new Date(a.end_at).getTime()
              );
            });
          if (sorted[0]) {
            teamDate = sorted[0].end_at;
          }
        }
        // к дате окончания попытки или дате последней попытки
        if (teamDate) {
          date = dayjs(teamDate).add(TEAM_STAGE_ENROLL_DAYS, "days");
        }
      }
      if (date) {
        return dayjs(date).format(DATE_FROMAT_WITH_UTC);
      }
      return undefined;
    },
    firstAttempt() {
      const { activity } = this;
      if (!activity?.attempts?.length) return undefined;
      return getFirstAttempt(activity.attempts);
    },
    /**
     * Наставник, должен иметь возможность проходить
     * задания только после окончания первой попытки
     */
    mentorCanEnroll() {
      const { mentorEnrollmentDate, isMentor, firstAttempt } = this;
      if (!isMentor || !mentorEnrollmentDate || !firstAttempt) return false;
      return dayjs().isAfter(dayjs(mentorEnrollmentDate, DATE_FROMAT_WITH_UTC));
    },
  },
  methods: {
    handleProfileClick(id) {
      if (!id) return;
      const { isMentor } = this;
      const link = {
        params: {},
      };
      if (isMentor) {
        link.name = "mentor-profile";
        link.params.id = id;
      } else {
        if (this.activity?.stage === TEAM_STAGE && this.isHighSchool) {
          link.name === "user-profile-stage-2";
        } else {
          link.name = "user-profile-stage-1";
        }
        link.params.profile_id = id;
      }
      this.$router.push(link);
      this.$emit("close");
    },
  },
};
</script>

<style></style>
